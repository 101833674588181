
import { mapMutations, mapState } from 'vuex';
import SingleSelect from '@/components/ui/singleSelect';
import PrimaryButton from '@/components/ui/primaryButton';
import WpImage from '~/components/ui/img.vue';

export default {
  name: 'ChatForm',

  components: {
    SingleSelect,
    PrimaryButton,
    WpImage,
  },

  props: {
    defaultClinic: {
      type: Object,
      default: () => {},
    },
    modal: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      /* sent: false, */
      loading: false,
      message: null,
      name: null,
      email: null,
      phone: null,
      chatContent: null,
      clinic: null,
      consent: false,
      portugalDefaultOptions: [
        { id: 'info', name: this.$t('contactForm.moreInfo') },
        { id: 'online', name: this.$t('contactForm.online') },
      ],
      spanishDefaultOptions: [
        { id: 'online', name: this.$t('contactForm.online') },
      ],
      error: {
        clinic: false,
        name: false,
        phone: false,
        invalidPhone: false,
        consent: false,
      },
    };
  },

  computed: {
    ...mapState({
      clinics: (state) => state.clinics,
      closestClinicId: (state) => state.closestClinicId,
      chatFormOpened: (state) => state.chatFormOpened,
      chatFormSent: (state) => state.chatFormSent,
      patientId: (state) => state.patientId,
      user: (state) => state.user,
      utm: (state) => state.utm,
      chatImage: (state) => state.options.chat_image,
    }),

    defaultOptions() {
      return this.isSpanish
        ? this.spanishDefaultOptions
        : this.portugalDefaultOptions;
    },

    displayConsentCheckbox() {
      return this.isPortuguese || this.isSpanish;
    },

    submitButtonMessage() {
      return this.chatFormSent
        ? this.$t('forms.success')
        : `${this.$t('chatForm.submit')}`;
    },

    clinicOptions() {
      return [
        ...this.clinics.map((c) => ({
          id: c.fields.sage_id,
          name: c.title.rendered
            .replace(/&amp;/gi, '&')
            .replace(/&#8211;/gi, '-'),
        })),
        ...this.defaultOptions,
      ];
    },

    inputs() {
      return `${this.chatContent}${this.name}${this.phone}${this.clinic}${this.consent}`;
    },
  },

  watch: {
    inputs() {
      this.resetMessage();
    },
  },

  async mounted() {
    const { data } = await this.$wp.clinics();
    if (data.length) {
      this.setClinics(data);
    }
    if (this.defaultClinic) {
      this.clinic = this.defaultClinic.fields.sage_id;
    } else if (this.closestClinicId) {
      this.clinic = this.clinics.find(
        (c) => c.id === this.closestClinicId
      ).fields.sage_id;
    }
    if (this.user) {
      this.email = this.user.email;
      this.name = this.user.name;
      this.phone = this.user.phone;
    }
  },

  beforeMount() {
    this.setChatFormUnsent();

    if (this.chatFormOpened) {
      document.body.classList.add('no-scroll');
    }
  },

  beforeDestroy() {
    document.body.classList.remove('no-scroll');
  },

  methods: {
    ...mapMutations([
      'closeChatForm',
      'toggleChatForm',
      'setClinics',
      'setChatFormSent',
      'setChatFormUnsent',
    ]),

    resetMessage() {
      this.message = null;
      this.error.clinic = false;
      this.error.name = false;
      this.error.phone = false;
      this.error.invalidPhone = false;
      this.error.consent = false;
    },

    validatePhone(phone) {
      const validPhone = phone.replace(/[^0-9\s\-()+]/g, '');
      return phone === validPhone;
    },

    async submit() {
      if (!this.chatFormSent) {
        this.loading = true;
        if (
          !this.clinic ||
          !this.name ||
          !this.phone ||
          (this.isSpanish && this.phone && !this.validatePhone(this.phone)) ||
          (this.displayConsentCheckbox && !this.consent)
        ) {
          this.loading = false;
          if (!this.clinic) {
            this.error.clinic = true;
          }
          if (!this.name) {
            this.error.name = true;
          }
          if (!this.phone) {
            this.error.phone = true;
          }
          if (this.isSpanish && this.phone && !this.validatePhone(this.phone)) {
            this.error.invalidPhone = true;
          }
          if (this.displayConsentCheckbox && !this.consent) {
            this.error.consent = true;
          }
        } else if (
          this.chatContent &&
          !this.error.clinic &&
          !this.error.name &&
          !this.error.phone &&
          !this.error.invalidPhone &&
          !this.error.consent
        ) {
          try {
            await this.$bolev.createLead({
              chatContent: this.chatContent,
              name: this.name,
              phone: this.phone,
              source: 'website',
              clinicId: this.clinic,
              patientId: this.patientId,
              formId: 'chat',
              utm: this.utm,
            });
            this.loading = false;
            this.setChatFormSent();
            this.message = this.$t('contactForm.thankYou', { name: this.name });
          } catch (_) {
            this.loading = false;
            this.message = this.$t('forms.invalid');
          }
        } else {
          this.loading = false;
          this.message = this.$t('forms.empty');
        }
      }
    },
  },
};

import { render, staticRenderFns } from "./contactForm.vue?vue&type=template&id=2616c91c"
import script from "./contactForm.vue?vue&type=script&lang=js"
export * from "./contactForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SingleSelect: require('/vercel/path0/components/ui/singleSelect.vue').default,Field: require('/vercel/path0/components/ui/field.vue').default,ConsentCheckbox: require('/vercel/path0/components/ui/consentCheckbox.vue').default,PrimaryButton: require('/vercel/path0/components/ui/primaryButton.vue').default})

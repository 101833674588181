
export default {
  name: 'Field',

  props: {
    value: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: null,
    },
    placeholderHtml: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      internValue: null,
    };
  },

  watch: {
    value(newValue) {
      if (newValue !== this.internValue) {
        this.internValue = newValue;
      }
    },
  },

  mounted() {
    this.internValue = this.value;
  },

  methods: {
    changed(event) {
      this.internValue = event.target.value;
      this.$emit('input', this.internValue);
    },

    enter() {
      this.$emit('enter');
    },
  },
};


export default {
  name: 'CustomBlock',

  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
